import { types as sdkTypes } from '../util/sdkLoader';

const { LatLng, LatLngBounds } = sdkTypes;

// An array of locations to show in the LocationAutocompleteInput when
// the input is in focus but the user hasn't typed in any search yet.
//
// Each item in the array should be an object with a unique `id` (String) and a
// `predictionPlace` (util.types.place) properties.
//
// NOTE: these are highly recommended, since they
//       1) help customers to find relevant locations, and
//       2) reduce the cost of using map providers geocoding API
// const defaultLocations = [
//   {
//     id: 'default-helsinki',
//     predictionPlace: {
//       address: 'Helsinki, Finland',
//       bounds: new LatLngBounds(new LatLng(60.29783, 25.25448), new LatLng(59.92248, 24.78287)),
//     },
//   },
//   {
//     id: 'default-turku',
//     predictionPlace: {
//       address: 'Turku, Finland',
//       bounds: new LatLngBounds(new LatLng(60.53045, 22.38197), new LatLng(60.33361, 22.06644)),
//     },
//   },
//   {
//     id: 'default-tampere',
//     predictionPlace: {
//       address: 'Tampere, Finland',
//       bounds: new LatLngBounds(new LatLng(61.83657, 24.11838), new LatLng(61.42728, 23.5422)),
//     },
//   },
//   {
//     id: 'default-oulu',
//     predictionPlace: {
//       address: 'Oulu, Finland',
//       bounds: new LatLngBounds(new LatLng(65.56434, 26.77069), new LatLng(64.8443, 24.11494)),
//     },
//   },
//   {
//     id: 'default-ruka',
//     predictionPlace: {
//       address: 'Ruka, Finland',
//       bounds: new LatLngBounds(new LatLng(66.16997, 29.16773), new LatLng(66.16095, 29.13572)),
//     },
//   },
// ];
const defaultLocations = [
  {
    id: 'default-london',
    predictionPlace: {
      address: 'London, United Kingdom',
      bounds: new LatLngBounds(new LatLng(51.6918741, -0.3340155), new LatLng(51.2867602, 0.2808486)),
    },
  },
  {
    id: 'default-manchester',
    predictionPlace: {
      address: 'Manchester, United Kingdom',
      bounds: new LatLngBounds(new LatLng(53.55073, -2.217275), new LatLng(53.35014, -2.07011)),
    },
  },
  {
    id: 'default-birmingham',
    predictionPlace: {
      address: 'Birmingham, United Kingdom',
      bounds: new LatLngBounds(new LatLng(52.569498, -1.786944), new LatLng(52.367778, -1.541309)),
    },
  },
  {
    id: 'default-edinburgh',
    predictionPlace: {
      address: 'Edinburgh, United Kingdom',
      bounds: new LatLngBounds(new LatLng(55.980279, -3.116259), new LatLng(55.868058, -3.002246)),
    },
  },
  {
    id: 'default-glasgow',
    predictionPlace: {
      address: 'Glasgow, United Kingdom',
      bounds: new LatLngBounds(new LatLng(55.917, -4.389), new LatLng(55.796, -4.245)),
    },
  },
];
export default defaultLocations;
